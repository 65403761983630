import React, { useState } from 'react'
import './Header.scss';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { FloatLabel } from 'primereact/floatlabel';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Post } from '../../Config/Config';

function Header() {

    const [visible, setVisible] = useState(false);

    const [username, setUsername] = useState('');
    const [usernameValid, setUsernameValid] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordValid, setPasswordValid] = useState(false);

    const [newUsername, setNewUsername] = useState('');
    const [newusernameValid, setNewUsernameValid] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordValid, setNewPasswordValid] = useState(false);

    const userId = JSON.parse(localStorage.getItem('AdminData')); 
    

    const userUpdate = () => {
        Post('GTAdmin/ChangeCredentials', {
            AdminId: userId.AdminId,
            Username: username,
            Pass: password,
            newUsername: newUsername,
            newPass: newPassword,
        }).then((res) => {
            if (res) {
                if (res.status === 1) {
                    setVisible(false)
                }
            }  
        })
    }


    const onHandleChange = (value, name) => {
        if (name === 'username') {
            setUsername(value);
            if (value) {
                setUsernameValid(false);
            } else {
                setUsernameValid(true);
            }
        } else if (name === "password") {
            setPassword(value);
            if (value) {
                setPasswordValid(false);
            } else {
                setPasswordValid(true);
            }
        } else if (name === "newusername") {
            setNewUsername(value);
            if (value) {
                setNewUsernameValid(false);
            } else {
                setNewUsernameValid(true);
            }
        } else if (name === "newpassword") {
            setNewPassword(value);
            if (value) {
                setNewPasswordValid(false);
            } else {
                setNewPasswordValid(true);
            }
        }
    }


    const headerElement = (
        <div className="inline-flex align-items-center justify-content-center gap-2">
            <span className="font-bold white-space-nowrap">Change Password</span>
        </div>
    );

    const footerContent = (
        <div>
            <Button label="Save" icon="pi pi-check" onClick={() => userUpdate()} autoFocus />
        </div>
    );

    const handleChangePass = () => {
        setVisible(true)
    }

    return (
        <div id='Header'>
            <div className='header-content'>
                <div className='title'>
                    <span onClick={() => handleChangePass()}>Change Password</span>
                </div>
            </div>

            <Dialog
                visible={visible}
                modal header={headerElement}
                footer={footerContent}
                onHide={() => { if (!visible) return; setVisible(false); }}
                id='changePassword'
            >
                <div className='contant'>
                    <div className='body'>
                        <div className="input">
                            <FloatLabel>
                                <InputText
                                    keyfilter='alphanum'
                                    value={username}
                                    className={usernameValid ? "p-invalid" : ""}
                                    onChange={(e) => onHandleChange(e.target.value, 'username')}
                                    name="User Name"
                                    id="username"
                                    autoFocus={true}
                                />
                                <label htmlFor="username">User Name</label>
                            </FloatLabel>
                        </div>

                        <div className="input">
                            <FloatLabel>
                                <Password
                                    onChange={(e) => onHandleChange(e.target.value, 'password')}
                                    value={password}
                                    className={passwordValid ? "p-invalid" : ""}
                                    name={"Password"}
                                    id={"password"}
                                    feedback={false}
                                    toggleMask
                                />
                                <label htmlFor="password">Password</label>
                            </FloatLabel>
                        </div>
                        <div className="input">
                            <FloatLabel>
                                <InputText
                                    keyfilter='alphanum'
                                    value={newUsername}
                                    className={newusernameValid ? "p-invalid" : ""}
                                    onChange={(e) => onHandleChange(e.target.value, 'newusername')}
                                    name="User Name"
                                    id="username"
                                    autoFocus={true}
                                />
                                <label htmlFor="Newusername">New User Name</label>
                            </FloatLabel>
                        </div>

                        <div className="input">
                            <FloatLabel>
                                <Password
                                    onChange={(e) => onHandleChange(e.target.value, 'newpassword')}
                                    value={newPassword}
                                    className={newPasswordValid ? "p-invalid" : ""}
                                    name={"Password"}
                                    id={"password"}
                                    feedback={false}
                                    toggleMask
                                />
                                <label htmlFor="Newpassword">New Password</label>
                            </FloatLabel>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

export default Header