import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";
import Header from "../Componets/Header/Header"; 

function Layout() {

    return (
        <React.Fragment>
            <Header /> 
            <div className="board">
                <Suspense><Outlet /></Suspense>
            </div>
        </React.Fragment>
    );
}
export default Layout;