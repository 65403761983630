import axios from 'axios'; 

const API_BASE_URL = "";
// const API_BASE_URL = "http://192.168.1.102:5232";
// const API_BASE_URL = "http://49.43.34.101:1445";

// const API_BASE_URL = "";

const CancelToken = axios.CancelToken;
let cancel;


const cancelRequest = () => {
    if (cancel) {  
        cancel('Request canceled by user');
    }
};

let pathname = ''

const checkFor404Error = () => {
    const errorValue = localStorage.getItem("Error");
    if (errorValue === "404") {
        window.location.assign('/404');
    }
}; 
export async function Post(path, params) {
    checkFor404Error();
    cancelRequest(); 
    
    const headerValue = sessionStorage.getItem('header');
    const config = {
        headers: {
            'x-encryption-key': headerValue,
        },
        cancelToken: new CancelToken(function executor(c) {
            if (path !== "CmbFill") {
                if (pathname === path) {
                    cancel = c;
                };
                pathname = path;
            };
        })
    };

    try {
        const response = await axios.post(`${API_BASE_URL}/${path}`, params, config);
        if (response.status === 200) {

            pathname = "";
        }
        return response.data;
    } catch (error) {
        if (axios.isCancel(error)) {
            console.log('Request canceled', error.message);
        } else {
            if (error?.response?.status === 404) {
                sessionStorage.setItem("ID", false);
                sessionStorage.removeItem("header");
                localStorage.removeItem("AdminData");
                localStorage.setItem("Error", error.response.status);
                return window.location.assign('/404');
            };
        };
    };
};


export async function Get(path, params) {
    cancelRequest();

    const headerValue = sessionStorage.getItem('header');
    const config = {
        headers: {
            'x-encryption-key': headerValue
        },
        params: params,
        cancelToken: new CancelToken(function executor(c) {
            if (pathname === path) {
                cancel = c;
            };
        })
    };

    try {
        const response = await axios.get(`${API_BASE_URL}/${path}`, config);
        return response.data;
    } catch (error) {
        if (axios.isCancel(error)) {
            console.log('Request canceled', error.message);
        } else {
            throw error;
        };
    };
};
