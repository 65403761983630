import React from "react"
import PathConstants from "../Routes/PathConstants";
 
const Login = React.lazy(() => import("../Login/Login"));
const Home = React.lazy(() => import("../Componets/Home/Home"));
const Page404 = React.lazy(() => import("../Componets/Page404/Page404"));
const Page500 = React.lazy(() => import("../Componets/Page500/Page500"));


const routes = [
    { path: PathConstants.Login, element: <Login /> },
    { path: PathConstants.LoginPage, element: <Login /> },
    { path: PathConstants.Home, element: <Home /> },
    { path: '/404', element: <Page404 /> },
    { path: '/500', element: <Page500 /> },
];

export default routes;