import React, { useState } from 'react'
import './Login.scss'
import { FloatLabel } from 'primereact/floatlabel';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { Post } from '../Config/Config'
import { useNavigate } from 'react-router-dom';

function Login() {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [usernameValid, setUsernameValid] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordValid, setPasswordValid] = useState(false);


    const onHandleChange = (value, name) => {
        if (name === 'username') {
            setUsername(value);
            if (value) {
                setUsernameValid(false);
            } else {
                setUsernameValid(true);
            }
        } else if (name === "password") {
            setPassword(value);
            if (value) {
                setPasswordValid(false);
            } else {
                setPasswordValid(true);
            }
        }
    }

    const loginSave = (e) => { 
        e.preventDefault();
        Post('GTAdmin/Login', {
            Username: username,
            Pass: password
        }).then((res) => { 
            if (res) {
                if (res.status === 1) {
                    localStorage.setItem("ID", res.token);
                    localStorage.setItem("AdminData", JSON.stringify(res.AdminData));
                    sessionStorage.setItem("header", JSON.stringify(res.Header));
                    localStorage.setItem("login", true);
                    navigate(`/home`);
                }
            }
        })
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            loginSave();
        }
    };
    return (
        <div id='Login'>
            <div className='login-box'>
                <div className='header'>
                    <h3 >Login </h3>
                </div>
                <div className='body'>
                    <div className="input">
                        <FloatLabel>
                            <InputText
                                keyfilter='alphanum'
                                value={username}
                                className={usernameValid ? "p-invalid" : ""}
                                onChange={(e) => onHandleChange(e.target.value, 'username')}
                                name="User Name"
                                id="username"
                                autoFocus={true}
                            />
                            <label htmlFor="username">User Name</label>
                        </FloatLabel>
                    </div>

                    <div className="input">
                        <FloatLabel>
                            <Password
                                onChange={(e) => onHandleChange(e.target.value, 'password')}
                                value={password}
                                className={passwordValid ? "p-invalid" : ""}
                                name={"Password"}
                                id={"password"}
                                feedback={false}
                                onKeyDown={handleKeyDown}
                                toggleMask
                            />
                            <label htmlFor="password">Password</label>
                        </FloatLabel>
                    </div>
                </div>
                <div className='footer'>
                    <Button label="Login" onClick={loginSave} />
                </div>
            </div>
        </div>
    )
}

export default Login